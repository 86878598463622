var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.availableActions,"inactiveFilterValue":_vm.inactiveFilterValue,"showInactiveFilter":"","containerMedium":""},on:{"switch-inactive":_vm.switchInactive,"create-new":_vm.newGroup}},[_c('template',{slot:"content"},[_vm._l((_vm.tenants),function(tenant){return _c('v-card',{key:tenant.tenant_id,staticStyle:{"margin-bottom":"15px"},attrs:{"elevation":"4"}},[_c('v-card-text',[_c('v-treeview',{attrs:{"item-disabled":"disabled","items":_vm.companiesTree[tenant.tenant_id],"load-children":_vm.loadChildren,"open-on-click":"","transition":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.type == 'group' && item.disabled ? 'inactive-row' : ''},[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.type == 'group')?[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.data.external_group && _vm.isCompanyAdmin(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editGroup(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:item.type == 'group' && item.disabled ? 'inactive-row' : ''},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showGroupUsers(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:item.type == 'group' && item.disabled ? 'inactive-row' : ''},[_vm._v(" mdi-account-details ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('group_users')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.data.external_group && _vm.isCompanyAdmin(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.promptRemoveGroupAccess(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:item.type == 'group' && item.disabled ? 'inactive-row' : ''},[_vm._v(" mdi-home-remove ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('remove')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.data.external_group)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showExternalCompanies(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:item.type == 'group' && item.disabled ? 'inactive-row' : ''},[_vm._v(" mdi-home-export-outline ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('external_companies')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isCompanyAdmin(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openSetSecurityClearanceModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:item.type == 'group' && item.disabled ? 'inactive-row' : ''},[_vm._v(" mdi-security ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Security clearance')))])])]:_vm._e()]}}],null,true)})],1)],1)}),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.getModalTitle()},on:{"submit":_vm.save,"modal-closed":_vm.resetSelectedGroup},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DynamicFormContent',{attrs:{"fields":_vm.fields},on:{"tenant-change":_vm.handleSelectedTenant}})]},proxy:true}])}),_c('DefaultModal',{ref:"removeExternalGroupModal",attrs:{"title":_vm.$t('Remove Access to Group'),"submit-button-text":"remove","deleteModal":""},on:{"submit":_vm.removeExternalGroup},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to remove access to the group'))+" "),_c('b',[_vm._v(_vm._s(_vm.selectedGroupName))]),_vm._v(" ? ")]},proxy:true}])}),_c('SecurityClearanceModal',{ref:"securityClearanceModal"})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }